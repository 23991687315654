.SalesScreen {
  text-align: center;
}

.SalesScreen-logo {
  height: 40vmin;
}
.circle-numbers {
  left: 6.5em;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.SalesScreen-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ant-select-selection-selected-value {
  font-size: 0.9em;
  font-weight: 500;
  color: grey;
  padding-left: 0.3em;
}
.sell-it-button-home {
  animation: bounce2 3s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.dot-line {
  margin: auto;
  position: absolute;
  margin-left: 7em;
  margin-top: 22em;
  width: 47em;
  height: 67em;
}
.header-links {
  position: absolute;
  right: 32em;
  white-space: nowrap;
  margin-left: -3%;
  margin-top: -5em;
  font-size: 16px;
}
a:hover {
  text-decoration: underline;
}

.footer-links {
  margin-left: 24%;
  margin-top: -4.7em;
  font-size: 16px;
  line-height: 2.2em;
}
.footer-links-div {
  margin-left: 46%;
  margin-top: -9em;
  font-size: 16px;
  line-height: 2.2em;
}
.footer-text {
  margin-left: 75%;
  margin-top: -6em;
  font-size: 16px;

  line-height: 1.2em;
}
.contact-text {
  margin-left: 10%;
  margin-top: 4.3em;
  font-size: 20px;

  line-height: 1.2em;
}
.footer-watermark {
  margin-top: 4em;
  font-size: 12px;

  line-height: 1.2em;
}
#loginButtons.header-button-dealer {
  position: absolute;
  right: 4em;
  padding-left: 17px;
  padding-top: 0px;
  margin-top: -3.8em;
  font-size: 18px;
  width: 10em;
  height: 2.6em;
  border-radius: 3em;
}
#loginButtons.header-button-customer {
  position: absolute;
  right: 15em;
  padding-left: 17px;
  padding-top: 0px;
  margin-top: -3.8em;
  font-size: 18px;
  width: 9em;
  height: 2.6em;
  border-radius: 3em;
}
.ant-card-head-title {
  text-align: left;
  color: rgba(34, 34, 34, 0.952);
  display: inline-block;

  padding: 0px 3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-bottom: -3.5em;
}

.start-buttons {
  padding-left: 20px;
  font-size: 19px;
  width: 12em;
  height: 2.7em;
  border-radius: 3em;
  top: 1.5em;
  left: -2em;
}
.dealer-buttons {
  padding-left: 20px;
  font-size: 19px;
  width: 12em;
  height: 2.7em;
  border-radius: 3em;
}
.ant-select-selection {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  border: 1.5px solid rgba(0, 135, 142, 0.623);

  height: 2.27em;
  border-radius: 0.3em;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sell-buttons {
  padding-left: 10px;
  top: -3.83em;
  font-size: 26px;
  width: 66%;
  min-width: 4em;
  height: 3.9em;
  border-radius: 0em;
  opacity: 1;
  border-top-right-radius: 3em;
  border-bottom-right-radius: 3em;
  background-image: linear-gradient(to left, #00b4bc, rgb(0, 135, 142));
  background-color: #00878e00;
  margin-bottom: 4em;
  margin-left: 97%;
  border: 0px solid rgb(255, 255, 255);
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 8px 3px -3px rgba(0, 179, 188, 0.219);
  -webkit-animation: button-animation 9s infinite;
  -webkit-animation-delay: 1s;
  animation: button-animation 9s infinite linear;
  animation-delay: 1s;
  transform: scale(1);
}

@keyframes button-animation {
  50% {
    width: 73%;
  }
  100% {
  }
}
.car-select-bar {
  transform: scale(1);
}

.sell-buttons:hover {
  background-image: linear-gradient(
    to left,
    rgb(3, 183, 192),
    rgb(0, 135, 142)
  );
}

.ant-dropdown-car-details {
  margin-top: -4em;
  margin-left: 0px;
  font-size: 15px;
  color: #666666b4;
}
.SalesScreen-link {
  color: #09d3ac;
}
.home-content-text {
  font-family: "Hind", sans-serif;
  font-size: 55px;
  font-weight: 500;
  line-height: 0.5em;
  margin-top: 0.5em;

  color: #00b4bc;
  letter-spacing: -2px;
}
.home-content-text-1 {
  position: absolute;
  font-size: 58px;
  font-weight: 500;
  line-height: 1.2em;
  top: 5em;
  margin-left: 8%;
  color: #00b4bc;
  letter-spacing: -2px;
}
.verkoopnou-logo-text {
  position: absolute;
  font-size: 13px;
  margin-top: -1.9em;
  margin-left: 0.15%;
  color: #00b4bc;
}
.sell-content-text {
  font-size: 20px;
  margin-top: -1em;
  margin-left: 3.8%;
}

.ant-layout-header {
  height: 90px;
  padding: 0 50px;
  line-height: 64px;
  background: #ffffff00;
}
.ant-layout-header:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 100%; /* Change this to whatever width you want. */
  padding-top: 20px; /* This creates some space between the element and the border. */
  border-bottom: 0px solid rgba(0, 0, 0, 0.055); /* This creates the border. Replace black with whatever color you want. */
}

.verkoopnou-logo {
  z-index: 9999 !important;
  margin-top: 2em;
  margin-left: 0%;
  width: 8em;
  height: 5em;
}
.footer-logo {
  margin-top: 5.3em;
  margin-left: 2%;
  width: 8em;
}
.bottom-footer {
  margin-top: 6em;
  margin-left: -3em;
  height: 0em;
  background-color: rgba(223, 223, 223, 0);
  border-top: 0.5px solid #5f5f5f85;
  z-index: 999;
}
.sa-flag {
  position: absolute;
  bottom: 0.6em;
  left: 1em;
  width: 3em;
  height: 3em;
  border-radius: 9em;
}
.circle-big {
  position: absolute;
  top: 70em;
  right: -20em;
  width: 35%;
  min-width: 30em;
  z-index: -1;
  transform: rotate(40deg);
  z-index: 0;
}
.circle-big-1 {
  position: absolute;
  top: 120em;
  left: -24em;
  width: 45%;
  min-width: 40em;
  z-index: -1;
  transform: rotate(220deg);
}
.silver-car {
  margin-top: -28em;
  transform: translateX(60em);
  right: -70em;
  width: 120em;
  -webkit-animation: car-move 3s forwards;
  -webkit-animation-delay: 1s;
  animation: car-move 3s forwards;
  animation-delay: 1s;
  z-index: 0;
  transition-duration: 0.5s;
}

@-webkit-keyframes car-move {
  100% {
    right: 0em;
  }
}
@keyframes car-move {
  100% {
    transform: translateX(45em);
  }
}

.silver-car-wheel {
  margin-top: -22em;
  width: 16em;
  -webkit-animation: wheel-move 3s forwards;
  -webkit-animation-delay: 1s;
  animation: wheel-move 3s forwards;
  animation-delay: 1s;
  transform: translateX(76.5em) rotate(-270deg) scale(1.04);

  transition-duration: 0.5s;
}
@-webkit-keyframes wheel-move {
  100% {
    right: 3em;
  }
}
@keyframes wheel-move {
  100% {
    right: 35em;
    transform: translateX(61.5em) rotate(-390deg) scale(1.04);
  }
}

.map-rectangle {
  overflow: hidden !important;
  position: absolute;
  top: -15em;
  left: 0;
  width: 100%;

  height: 70em;
  z-index: -1;

  background-size: cover;
}
.sold-vehicles {
  position: absolute;
  top: 15em;
  left: 0%;
  width: 85%;
  min-width: 30em;
  max-width: 50em;
}
.stats-pic {
  position: absolute;
  top: 10em;
  right: 8em;
  width: 85%;
  min-width: 26em;
  max-width: 35em;
}
.money-transfer-pic {
  position: absolute;
  top: 16em;
  left: 0%;
  width: 85%;
  min-width: 30em;
  max-width: 40em;
}
.car-hood-pic {
  position: absolute;
  margin-top: 3em;
  left: -6em;
  width: 25%;
}
.ant-card-body {
  padding-left: 20px;
  padding-top: 0;
  zoom: 1;
}
.ant-card-head {
  background-color: rgba(0, 135, 142, 0);
  color: #00b4bc;
  font-size: 30px;
  border-bottom: 0px solid #e8e8e8;
}
#brand-card-head .ant-card-head {
  background-color: #00878e00;
}
#year-card-head .ant-card-head {
  background-color: #00878e00;
}
.ant-card {
  box-shadow: 0 8px 3px -4px rgba(0, 179, 188, 0.171);
}

.ant-tabs {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  overflow: hidden;
  zoom: 1;
}

.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: 5%;
  margin-bottom: 0;
  border-right: 0px solid #e8e8e8;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
  border-bottom: 1.5px solid #e8e8e8;
  text-align: center;
}

.ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 0px solid #e8e8e8;
}
.ant-layout-content {
  -ms-flex: auto;
  flex: auto;

  padding: 0px !important;
  margin-left: 3em;
  max-height: 70vh;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 0px;
  height: auto;
}

.ant-tabs-nav-container {
  transition: 0.3s;
  text-align: center;
  width: calc(100% + 40px);
  color: rgba(0, 0, 0, 0.459);
  font-size: 19px;
  font-family: roboto;
  font-weight: bolder;
  border-radius: 10px;
  margin-bottom: 30%;
  padding: 8px 24px;
  background-color: #ffff;
  box-shadow: 3px 4px 10px 2px #8888881f;
}

.ant-tabs-nav .ant-tabs-tab-active {
  transition: 0.3s;
  background-color: #0f877b;
  text-align: center;
  width: calc(100% + -0px);
  color: white !important;
  border-radius: 5px;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  padding-top: 0.5em;
  padding-bottom: 1em;
  height: 100%;
}

.card-price {
  display: inline;
}
.ant-badge-status-dot {
  position: relative;
  top: -3px;
  left: 135px;
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-radius: 50%;
}

.state-green {
  background-color: green;
}

.state-orange {
  background-color: orange;
}
.state-red {
  background-color: red;
}
.plus-circle {
  top: 1.4em;
  left: 3.2em;
  color: rgba(122, 122, 122, 0.459);
  font-size: 25px;
  font-weight: 100 !important;
}
@media only screen and (max-width: 770px) {
  .lang-radio {
    margin-top: 0em !important;
  }
  .choose-car-card {
    margin-top: -5em;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, 0.493) !important;
    width: 100vw !important;
    z-index: 1 !important;
  }
  .silver-car {
    margin-top: -25em;
    overflow: hidden !important;
  }
  @-webkit-keyframes car-move {
    100% {
      right: 0em;
    }
  }
  @keyframes car-move {
    100% {
      transform: translateX(13em);
    }
  }

  .silver-car-wheel {
  }
  @-webkit-keyframes wheel-move {
    100% {
      right: 3em;
    }
  }
  @keyframes wheel-move {
    100% {
      right: 35em;
      transform: translateX(29.6em) rotate(-390deg) scale(1.04);
    }
  }
  #loginButtons.header-button-dealer {
    position: absolute;
    right: 0.5em;
    padding-left: 17px;
    padding-top: 0px;
    margin-top: -2.4em;
    font-size: 18px;
    transform: scale(0.8);
    width: 8em;
    height: 2.6em;
    border-radius: 3em;
  }
  #loginButtons.header-button-customer {
    position: absolute;
    right: 7em;
    padding-left: 17px;
    padding-top: 0px;
    margin-top: -2.4em;
    font-size: 18px;
    transform: scale(0.8);
    width: 8.8em;
    height: 2.6em;
    border-radius: 3em;
  }
  .verkoopnou-logo {
    margin-top: 1em;
    margin-left: -1em;
    width: 6em;
    height: 4em;
  }
  .verkoopnou-logo-text {
    position: absolute;
    font-size: 11px;
    margin-top: -2.3em;
    margin-left: -1.35em;
    color: #00b4bc;
  }
  .header-links {
    right: 1em;

    white-space: nowrap;
    margin-left: -3%;
    margin-top: 0em;
    font-size: 16px;
  }
  .home-content-text {
    margin-top: -0em;
  }
  .home-content-text-1 {
    position: absolute;
    text-align: center;
    margin-left: -1em;
    white-space: nowrap;
    top: 3em;
    width: 100vw;
  }
  .circle-big {
    display: none;
  }
  .dot-line {
    display: none;
  }
  .circle-numbers {
    margin: auto;
    text-align: center;
    top: 3em;
    left: 54.5%;
  }
  .circle-numbers-2 {
    margin: auto !important;

    text-align: center;
    top: -9em;
    margin-left: -5.3em !important;
    left: 54.5%;
  }
  .circle-numbers-3 {
    margin: auto;
    text-align: center;
    top: -6em;
    left: 54.5%;
  }
  .how-it-header {
    margin: auto !important;
    text-align: center;
    margin-top: 8em !important;
    margin-left: -2em !important;
  }
  .how-it-paragraph {
    font-size: 18px !important;
    margin: auto !important;
    text-align: center;
    margin-top: 1em !important;
    margin-left: -2em !important;
  }
  .receive-header {
    margin: auto !important;
    text-align: center;
    margin-top: 13.5em !important;
    margin-left: -2em !important;
  }
  .receive-paragraph {
    font-size: 18px !important;
    margin: auto !important;
    text-align: center;
    margin-top: 1em !important;
    margin-left: -1.9em !important;
  }
  .paid-header {
    margin: auto !important;
    text-align: center;
    margin-top: 15.55em !important;
    margin-left: -2em !important;
  }
  .paid-paragraph {
    font-size: 18px !important;
    margin: auto !important;
    text-align: center;
    margin-top: 1em !important;
    margin-left: -2em !important;
  }
  .now-you-are-div {
    margin: auto !important;
    text-align: center;
    margin-top: -0em !important;
    margin-left: -3em !important;
  }
  .home-div {
    overflow: hidden;
  }
  .footer-logo {
    visibility: hidden;
    text-align: center;
    margin-top: -5em;
    margin-bottom: 0em;
    margin-left: 30%;

    width: 5em;
  }
  .footer-text {
    display: none;
    margin-left: 55%;
    margin-top: -12em;
    font-size: 15px;
    line-height: 1.2em;
  }
  .footer-links {
    text-align: center;
    margin-left: 0%;
    margin-top: -0em;
    font-size: 16px;
    line-height: 2.2em;
  }
  .footer-links-div {
    text-align: center;
    margin-left: 0%;
    margin-top: 0em;
    font-size: 16px;
    line-height: 2.2em;
  }

  .contact-text {
    margin-left: 10%;
    margin-top: 4.3em;
    font-size: 20px;

    line-height: 1.2em;
  }
  .footer-watermark {
    margin-top: 5em;
    font-size: 12px;

    line-height: 1.2em;
  }
  .sold-vehicles {
    transform: scale(0.8);
    left: -3.3em;
    top: 1em;
  }
  .stats-pic {
    transform: scale(0.8);
    left: -1.5em;
    top: 35em;
  }
  .money-transfer-pic {
    transform: scale(0.7);
    left: -2em;
    top: 0em;
  }
  .message-card {
    margin-top: -10em !important;
    margin-bottom: 45em !important;
    min-width: 182% !important;
    margin-left: -6em !important;
  }

  .contact-info-card {
    height: 31.5em !important;
    border-radius: 0 !important;
    margin-top: 40em !important;
    margin-left: -1em !important;
    min-width: 160% !important;
  }
  .header-button-send {
    max-width: 16em !important;
    min-width: 13.5em !important;
    margin-left: -4% !important;
    width: 59vw !important;
  }
  .facebook-icon {
    display: none;
  }
  .header-home {
    visibility: hidden;
  }
  .verkoopnou-logo {
    visibility: visible;
  }
  .verkoopnou-logo-text {
    visibility: visible;
  }
  .burger-menu {
    visibility: visible !important;
  }
  .ant-drawer-body {
    margin-top: 20%;
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  .verkoopnou-logo-menu {
    position: absolute;
    margin-top: 29em;
    margin-left: -0.6em;
    width: 5.5em;
    height: 4em;
  }
  .verkoopnou-logo-text-menu {
    position: absolute;
    font-size: 13px;
    margin-top: 35.5em;
    margin-left: 5em;
    color: #00b4bc;
  }
  .home-select {
    transform: scale(1.2);
  }
  .ant-card-head-title {
    font-size: 16px;
    margin-left: -0.7em;
    margin-bottom: -3.7em;
  }
}
